.chart {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  padding-top: 7rem;
  padding-bottom: 7rem;

}

.chart-container {
  width: 80%;
  margin: auto;
  padding: auto;
}

.chart-img {
  width: 100%;
  /* opacity: 1; */
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  /* transform-style: preserve-3d; */
}