.wealth {
    background: linear-gradient(94.96deg, rgba(81, 88, 246, 0.2) 0.8%, rgba(130, 45, 245, 0.2) 27.15%, rgba(243, 167, 97, 0.2) 97.42%);    
    padding: 5px 0;
    }
.wealth span {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 41.6327px;
    line-height: 60px;
    color: #212121;
}

.wealth h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 100px;
    background: linear-gradient(180deg, #666DFF 0%, #5057E4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.wealth p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 41.6327px;
    line-height: 50px;
    color: #212121;
}
.droneImage {
    width: 100%;
    animation: rotation infinite 10s linear;
}
@keyframes rotation {
    from {
      transform: rotateX(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }

  @media (max-width: 1200px) {
    .wealth {
        padding: 100px 0 20px 0;
    }

    .wealth h2 {
        font-size: 60px;
        line-height: 70px;
    }

    .man {        
        font-size: 39px;
        line-height: 60px;
        text-align: center;
        
    }

    .wealth p {
        font-size: 39px;
        line-height: 50px;
        

    }

    .imageContainer {
        margin-top: 2rem;
        margin-left: 2rem;
    }
}

@media (max-width: 1000px) {
    .wealth {
        padding: 100px 0 20px 0;
    }

    .wealth h2 {
        font-size: 60px;
        text-align: center;
    }
    .wealth span {        
        font-size: 39px;
        line-height: 60px;
        text-align: center !important;
    }

    .wealth p {
        font-size: 39px;
        line-height: 50px;
        text-align: center;
        

    }

    .imageContainer {
        margin-top: 2rem;
    }
}

