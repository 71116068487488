.outreach {
  position: relative;
  background: #100034;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  /* margin-top: -40rem; */
  padding: 7rem 3rem 7rem 5rem;
  color: #fff;
  background-image: url(../images/Shapes.png);
  background-size: 100%;
}

.outreach-container {
  width:75%;
  display: flex;
  flex-direction: column;

}

.outreach-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 72px;
  background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.outreach-subtitle-1 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  background: linear-gradient(90.76deg, #B671C9 -11.96%, #5960F5 22.52%, #41AFFA 68.36%, #585FF4 93.52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.outreach-subtitle {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 38px;
}

.outreach-details {
  width: 80%;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
}

.outreach-button {
  margin-top: 2rem;
  border-radius: 25px;
  border: 1px solid #F1C767;
  outline: none;
  color: #fff;
  padding: 0.7rem;
  width: 12rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 10px 22px -6px rgba(102, 109, 255, 0.26);
  transition: all 0.2s ease-in-out;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-smallest);
  line-height: 1.5rem;
/* identical to box height */


/* gOLD */

background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
padding: 10px 20px;
}

.outreach-button:hover {
  /* background: #666dff; */
  opacity: 0.5;
  /* transition: all 0.2s ease-in-out; */
  
}

.outreach-img {
  position: absolute;
  top: 22.80rem;
  right: 3rem;
  background-image: url('../images/coin-bag.png');
  width: 470px;
  height: 470px;
  background-repeat: no-repeat;
  background-position: cover;
  transition: transform .2s;
}

.outreach-img:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5);
}

@media (max-width: 1200px) {
  .outreach {
    padding: 7rem 1rem;
  }

  .outreach-img {
    display: none;
  }
  .outreach-container {
    width: 100%;
    text-align: center !important;
    align-items: center;
    justify-content: center;
  }
  .outreach-details {
    width: 100%;
  }
  .outreach-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}