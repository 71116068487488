.unbeatable {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    isolation: isolate;
    background: #100034;
    background-image: url('../images/framebg.png');
    background-size: 100%;
    padding: 2rem 0 2rem 0;
}

.unbeatable h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    background: linear-gradient(180deg, #666DFF 0%, #5057E4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent
}

.unbeatable p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}


.unbeatable .safecoin{
    width: 100%;
        transition: transform .2s;

}


.unbeatable .safecoin:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5);
}
.unbeatableTitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 120px;
    color: #ffff;
}



.unbeatabelehuge{
display: flex;
flex-direction: row;
align-items: center;

}

.unbeatable span{
    display: grid;
    /* place-items: left; */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 33.1839px;
    line-height: 52px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-left: 1.8rem;
    text-align: left;
    /* align-items: flex-start; */

}
.imgzoom {
    width: 100%;
    transition: transform .2s !important;
}

.imgzoom:hover {
    -ms-transform: scale(1.5) !important; /* IE 9 */
    -webkit-transform: scale(1.5) !important; /* Safari 3-8 */
    transform: scale(1.5) !important; 
}

@media (max-width: 500px) {
   .unbeatableContainer {
    display: none;
   }
}

@media (max-width: 1200px) {
    .unbeatableContainer {
     display: none;
    }

    .unbeatable span{
        font-size: 25px;
        line-height: 52px;    
    }
    .unbeatable p {
        font-size: 54px;
    }
    .unbeatable h2 {
        font-size: 54px;
    }
    
    
    
    
 }