@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;600;800&family=Nunito+Sans:wght@400;600;800;900&family=Open+Sans:wght@300;400;500&family=Space+Grotesk:wght@500;700&display=swap");

:root {
    --primary-color: #5057e4;
    --white-color: #ffffff;
    --tertiary-color: #191a38;
    --secondary-color: #b5bed4;
    --text-color: #5d5d6d;
    --black-color: #232536;
    --deep-blue-clolor: #13142c;
    --light-color: rgba(255, 255, 255, 0.74);
    --grey-color: #eeeefc;
    --left-card-color: linear-gradient(180deg, #3e43b1 0%, #8144a4 100%);
    --right-card-color: linear-gradient(180deg, #4e2e6b 0%, #8144a4 100%);
    --nav-color: #0f1021;
    --resources-card: #191a38;

    /* ----------------------------------- */
    --primary: #5057e4;
    --white: #ffffff;
    --tertiary: #191a38;
    --secondary: #b5bed4;
    --text: #5d5d6d;
    --black: #232536;
    --deep-blue: #13142c;
    --light: rgba(255, 255, 255, 0.74);
    --grey: #eeeefc;
    --left-card: linear-gradient(180deg, #3e43b1 0%, #8144a4 100%);
    --right-card: linear-gradient(180deg, #4e2e6b 0%, #8144a4 100%);
    --nav: #0f1021;

    /* Font-Families */
    --nunito: "Nunito Sans";
    --inter: "Inter";

    /* Font Sizes */
    --font-small: 11px;
    --font-smaller: 13px;
    --font-smallest: 14px;
    --font-mid: 16px;
    --font-med: 18px;
    --font-medium: 20px;
    --font-big: 22px;
    --font-bigger: 24px;
    --font-biggest: 27px;
    --font-large: 32px;
    --font-larger: 40px;
    --font-largest: 64px;

    --box-shadow: 0px 0px 7px rgba(65, 111, 65, 0.2);
    --shadow: 0px 0px 5px rgba(35, 37, 54, 0.25);
    --transition: all 0.2s ease-in-out;
}

/* Light theme */
.light-theme {
    --white: #000;
    --navbar-background: #fff;
    --navbar-text-color: #5d5d6d;
    --token-sales-background: #eeeefc;
    --vision-background: #fff;
    --faq-background: #13142c;
    --faq-card-background: #191a38;
    --faq-card-text-color: rgba(255, 255, 255, 0.74);
    --faq-card-boxshadow: 0px 5px 16px #12122c;
    --faq-icon-background: #1e1f41;
    --subscribe-background: linear-gradient(180deg, #0f1021 0%, #111227 100%);
    --footer-background: #0e0f1f;
    --scrollbar-background: #191a38;
    --registration-background: #f6f9fc;
    --registration-view-background: #fff;
    --border-color: #e6e8ec;
    --phone-input-background: #eeeefc;
    --input-box-shadow: 0px 64px 64px -48px rgba(177, 191, 224, 0.38);
    --personal-details-topbackground: #f8f8fa;
    --awesome-icon-background: #e6e8ec;
    --table-row-background-one: #fbfcfe;
    --table-row-background-two: #f4f7fc;
    --button-active-background: #23262f;
    --rail-background: #f6f6f6;
    --pay-deposit-background: #f1f5fa;
}

/* Dark theme */
.dark-theme {
    --white: #fff;
    --navbar-background: #09090b;
    --navbar-text-color: #e1e1e7;
    --token-sales-background: #181821;
    --vision-background: #09090b;
    --faq-background: #09090b;
    --faq-card-background: #181821;
    --faq-card-text-color: #9898b4;
    --faq-card-boxshadow: 0px 5px 16px #0e0e0e;
    --faq-icon-background: #2a2a39;
    --subscribe-background: #181821;
    --footer-background: #09090b;
    --scrollbar-background: #181821;
    --registration-background: #09090b;
    --registration-view-background: #181821;
    --border-color: #414144;
    --phone-input-background: #353945;
    --input-box-shadow: none;
    --personal-details-topbackground: #2a2a39;
    --identity-box-background: #09090b;
    --awesome-icon-background: #1e1f33;
    --table-row-background-one: #353945;
    --table-row-background-two: #2a2a39;
    --button-active-background: #5057e4;
    --rail-background: #5d5d6d;
    --pay-deposit-background: #2a2a39;
}

/* =========================== */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: var(--nunito);
    background: var(--navbar-background);
}

a {
    text-decoration: none !important;
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--scrollbar-background);
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
    background: #001240;
    border-radius: 10px;
    cursor: pointer;
}

/* [data-theme="dark"] {
    --font-color: #eee;
    --background-color: #333;
    --link-color: lightblue;
  } */

/* Custom Dark Mode Toggle Element */

.toggle-theme-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggle-theme-wrapper span {
    font-size: 28px;
    margin: 0 4px;
}

.toggle-theme {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
}

.toggle-theme input {
    display: none;
}

.slider {
    background-color: #ccc;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
}

.slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

input:checked + .slider {
    background-color: cornflowerblue;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.registration-div {
    margin-bottom: 400px !important;
}

.hero-div {
    color: var(--white);
}
