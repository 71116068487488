.footerSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 17px 0;
    background: #000000;
}

.footerSection p {
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
text-align: center;
color: #FFFFFF;
}