nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition:  0.32s ease-in-out;
  }
  nav.navbar.scrolled {
    padding: 0px 0;
    background-color: #121212;
  }
  /* nav.navbar a.navbar-brand {
      width: 9%;
  } */
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.2px;
    padding: 0 auto;
    font-size: 13px;
    opacity: 0.75;
  }
  nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
  nav.navbar .navbar-nav a.nav-link.navbar-link.active {
      opacity: 1;
  }
  span.navbar-text {
      display: flex;
      align-items: center;
  }
  .navbar-text button {
    font-weight: 500;
    color: #000000;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
    border-radius: 50px;
    padding: 10px 34px;
    font-size: 14px;
    margin-left: 18px;
      position: relative;
      background-color: transparent;
      transition: 0.3s ease-in-out;
  }
  .navbar-text button span {
    z-index: 1;
  }
  .navbar-text button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .navbar-text button:hover {
    color: #121212;
    opacity: 0.7;
  }
  .navbar-text button:hover::before {
      /* content: "";
      width: 100%;
      height: 100%;
      position: absolute; */
  }
  nav.navbar .navbar-toggler:active,
  nav.navbar .navbar-toggler:focus {
      outline: none;
      box-shadow: none;
  }
  nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
  }
  nav.navbar .navbar-toggler-icon:focus {
      border-bottom: 2px solid #fff;
  }
  nav.navbar .navbar-toggler-icon:after,
  nav.navbar .navbar-toggler-icon:before {
      width: 24px;
      position: absolute;
      height: 2px;
      background-color: #fff;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
  }
  nav.navbar .navbar-toggler-icon:after {
      top: 8px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
      transform: rotate(45deg);
      background-color: #fff;
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
      transform: translateY(8px) rotate(-45deg);
      background-color: #fff;
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
      border-color: transparent;
  }


  @media (max-width: 900px) {

  }

  @media (max-width: 900px) {
    nav.navbar {
      background-color: #000000;
      opacity: 1;
      padding: 5px 0;
    }

    nav.navbar .navbar-nav .nav-link.navbar-link {
        padding-top: 1rem;
        text-align: center;
    }

    .navbar-text {
        margin-top: 1rem;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

}