* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
 font-family: 'DM Sans', sans-serif;
  
 /* background-color: #13142C;
  box-sizing: border-box; */
}

/* code {
  font-family: 'Poppins', sans-serif;
 font-family: 'DM Sans', sans-serif;
} */
