.token {
  background: #0E0F1F;
  padding: 7rem 0;
}

.token img {
  width: 95%;
}

.token h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 72px;
  background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 10px 40px rgba(143, 67, 242, 0.2);
}

.token h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 10px 40px rgba(143, 67, 242, 0.2);
}

.token p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #FFFFFF;
}

.token button {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 48px;
  gap: 10px;
  width: 197px;
  height: 56px;
  border-radius: 50px;
  border: 2px solid #B88717;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.token button:hover {
  border: 2px solid #B88717;
  opacity: 0.7;
}