.banner {
  margin-top: 0;
  padding: 160px 0 100px 0;
  background-image: url("../../images/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.banner h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: var(--font-largest);
  line-height: 4.5rem;
  color: #ffffff;
}

.banner p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-bigger);
  line-height: 2.5rem;
  color: #ffffff;
}

.banner span {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-large);
  line-height: 3rem;
  color: #f1c767;
}

.banner button {
  margin-top: 2rem;
  border-radius: 25px;
  border: 1px solid #f1c767;
  outline: none;
  color: #fff;
  padding: 0.7rem;
  width: 12rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 10px 22px -6px rgba(102, 109, 255, 0.26);
  transition: all 0.2s ease-in-out;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-smallest);
  line-height: 1.5rem;
  background: linear-gradient(
    90deg,
    #b88717 -5.35%,
    #ffd166 21.73%,
    #f5d281 42.92%,
    #c48e0e 107.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px 20px;
}

.banner button:hover {
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
}

.vision-img {
  width: 100%;
}

/* =================================== */
/* THE RESPONSIVENESS */
/* =================================== */

@media (max-width: 1200px) {
  .banner {
    margin-top: 0;
    padding: 250px 0 100px 0;
  }
  .banner h1 {
    font-size: 45px;
    line-height: 3.5rem;
  }

  .banner p {
    font-size: 21px;
    line-height: 2.5rem;
    color: #ffffff;
  }

  .banner span {
    font-size: 23px;
    line-height: 3rem;
  }
}

@media (max-width: 500px) {
  .banner {
    margin-top: 0;
    padding: 150px 0 100px 0;
  }
  .banner .right {
    display: none;
  }
  .banner .left {
    text-align: center;
  }
  .banner form {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
  }
  .banner h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .banner p {
    font-size: 20px;
  }
  .banner span {
    font-size: 25px;
  }
}

@media (max-width: 960px) {
  .banner button {
    margin: auto;
    margin-top: 2em;
  }
}
