.regulatoryinfoContainer{
    background-image: url('../images/linear-image.png');
   background-repeat: no-repeat;
   background-size: cover;
   justify-content: center;
   display: grid;
   place-items: center;
   padding: 70px 0;

}
.regulatoryinfoContainer  p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #ffff;
}

.regulatoryinfoContainerA{
    margin-top: 2rem;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #ffff;
    width: 100%;
}


.regulatoryinfoContainer ul{
    margin: auto;
    padding: auto;
    width: 70%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    }
.regulatoryinfoContainer ul li{
    list-style: none;
    margin: auto;
    padding: auto;
    }
.regulatoryinfoContainer ul li a{
   color: #ffff;
    }


    
    @media (max-width: 800px) {
        .regulatoryinfoContainer ul li a{
            color: #ffff;
            font-size: 16px;
             } 
             .regulatoryinfoContainerA {
                font-size: 16px;
             }

             .regulatoryinfoContainer ul{ 
                grid-template-columns: repeat(4, 1fr);
             }
    }   
    @media (max-width: 500px) {
        
         .regulatoryinfoContainer ul{ 
            grid-template-columns: repeat(2, 1fr);
            
         }
    }   