.unlimited {
    padding: 70px 0;
    background: #100034;
    background-image: url('../images/framebg.png');
    background-size: 100%;

    
} 

.unlimited .body {
    align-items: center;
    justify-content: center;
}

.unlimited img {
    display: flex;
    align-self: flex-end;
    justify-content: end;

}

.unlimitedsubTitleimage:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5); 
  }

  .unlimited h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 120px;
    color: #F1C767;
  }

.bold {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .2s;

}

 .bold:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5);
}

.unlimited p {
font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 50px;
color: #A5A5FF;
}

@media (max-width: 1200px) {

    .unlimited {
        padding: auto;
        margin: auto;
    }

    .unlimited h2 {
        font-size: 60px;
    }
    .unlimited .body {
        text-align: center;
    }
    .unlimitedsubTitleimage {
        align-items: center;
        justify-content: center;
        /* margin-left: 15rem; */
    }
}