.banner2 {
  padding: 160px 0;
  background-image: url('../../images/banner2.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.banner2 h1 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 130px;
  /* or 135% */


  /* gOLD */

  background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  /* UAB Card Shadow */

  text-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
}

@media (max-width: 500px) {
  .banner2 h1 {
    text-align: center;
    font-size: 76px;
  }
}