.share-icon {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 0.9rem;
    margin-top: 20px;
}

.share-icon div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    transition: var(--transition);
    border: 1px solid #fff;
}

.share-icon div:hover {
    transform: scale(1.3);
    border: 1px solid var(--primary-color);
}

.share-icon .shares {
    font-size: var(--font-mid);
    cursor: pointer;
    color: #fff;
}

@media (max-width: 530px) {
    .share-icon {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (max-width: 373px) {
    .share-icon {
        grid-template-columns: repeat(4, 1fr);
    }
}
