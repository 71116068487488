.section-instruction {
  background: #0E0F1F !important;
  padding-bottom: 10rem;
}

.page-header {
  font-family: 'Poppins';
font-style: normal;
font-weight: 900;
font-size: 64px;
line-height: 72px;
text-align: center;
background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.accord {
  background-color: #333456 !important;
  backdrop-filter: blur(24px);
  border-radius: 20px;
  border: 1px solid transparent;
}



.accordion-button {
  background: rgba(255, 255, 255, 0.03) !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  color: #FFFFFF;
}

.accordion-button::after {
  font-family: "FontAwesome";
  content: "\2b";
  background-image: none;
}

.accordion-button:not(.collapsed)::after {
  font-family: "FontAwesome";
  content: "\f068";
  background-image: none;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #ffffff;
}
.accordion-body {
  /* color: #000000; */
  background: #595B83;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #FFFFFF;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

}

.accordion-button:focus {
  box-shadow: none;
}

.faq-section {
  margin-top: 12em;
}

/* MOBILE VIEW CODE  */

@media only screen and (max-width: 768px) {

  .white-background{
      padding-top: 10em;
      padding-bottom: 5em !important;
      
  }
  .topp {
      height: 600px;
  }
  .navbar-toggler {
      border: none !important;
      color: #ffffff;
  }

  .navbar-toggler-icon {
      color: #ffffff !important;
      background-color: #ffffff !important;
      border: none !important;
  }
  .switch-button {
      text-align: center;
  }

  .direct-lead button{
      margin:4em;
      margin-top: 2em !important;
      margin-bottom: 2em !important;
  }

  .white {
      margin-top: 1em;
      margin-bottom: 1em;
  }
  .section-coins {
      padding-top: 9em;
      padding-bottom: 9em;
  }
  .btcimg {
      margin-top: 8em;
  }
  .pathimg {
      display: none !important;
  }

  .wii {
      padding: 1em !important;
  }
  .footer-p {
      padding-right: 0;
      padding-left: 0;
  }
  .carder {
      margin-top: 5em;
  }

  .about {
      margin-bottom: 4em;
  }
  .who h3 {
      text-align: center;
  }
  .who p {
      text-align: center;
  }

  .section-coins h2 {
      text-align: center;
      margin-bottom: 2em;
  }
  .section-coins p {
      text-align: center;
  }
  .ourcryptobtn {
      text-align: center;
  }
  .process_top-row h3 {
      text-align: center;
      margin-bottom: 2em;
  }
  .mobile-1 {
      margin-top: 55em;
      right: 0;
  }
  .vertical-bg{
      height: 900px;
  }
  .coindImg{
      margin-top: 33em;
  }
  .card-head {
      text-align: center;
  }
  .page-header {
      text-align: center;
  }
}

