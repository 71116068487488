.waitlistpage {
    /* background-image: url('../images/coins.png'); */
    /* background-repeat: no-repeat; */
    /* background-position: center;  */
    background: #0E0F1F;
    padding: 1rem 0 7rem 0;

  }

  .rn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/coins.png');
      background-repeat: no-repeat;
      background-position: 20rem 0;

  }

  .waitlistpage .waitingListAccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 55.7617px 44.6094px;
    padding: 10rem 4rem 4rem 4rem;
    gap: 11.15px;
    isolation: isolate;
    width: 100%;
    background: rgba(51, 52, 86, 0.4);
    backdrop-filter: blur(13.3828px);
    border-radius: 17.8438px;
  }

  .waitingListAcces1 {
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 55.7617px 44.6094px;
    padding: 10rem 4rem 4rem 4rem;
    gap: 11.15px;
    isolation: isolate;
    width: 100%;
    background: rgba(51, 52, 86, 0.4);
    backdrop-filter: blur(13.3828px);
    border-radius: 17.8438px;
    text-align: center;
  }

  .waitlistpage .waitingListAccess h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #FFFFFF;
  }
 .waitlistpage .h2 {
  text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #FFFFFF;
    margin: auto;
  }

  .waitlistpage .h3 {
    text-align: center;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
/* identical to box height */


color: #FFFFFF;
  }

  .waitlistpage .h33 {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 54px;
/* identical to box height */

text-align: center;

/* Gold Gradient */

background: linear-gradient(99.74deg, #B88717 1.28%, #FFD166 29.81%, #F5D281 73.35%, #C48E0E 115.29%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  }

   .waitlistpage .p3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* or 200% */

    text-align: center;

    /* Color-text */

    color: #B1B5C3;
   }
  .waitlistpage .waitingListAccess p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-align: center !important;
    color: #B1B5C3;
  }

  .waitlistpage .line {
    width: 100%;
    background-color: #0079FF;
    height: 1px;
    margin-top: 6rem;

  }
 .waitlistpage .waitingListAccess p span {
  color:#0079FF;
 }

 .waitlistpage .span1 {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #FFFFFF;
 }
  .waitlistpage .waitingListAccess1 p span {
    
  color:#0079FF;
 }
 .waitlistpage .waitingListAccess .waitlistIconsA {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
 }

 .waitlistpage input{
  width: 100%;
  background: #333456;
  border-radius: 8.92188px;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: #fff;
 }

 .waitlistpage .waitingListAccess .p1 {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 28px !important;
text-align: center;
color: #B1B5C3;
 }

 .waitlistpage .waitingListAccess .p2 {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px !important;
text-align: center;
color: #777E91;
 }
 .waitlistpage .waitingListAccess h3 {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
/* identical to box height */


color: #FFFFFF;
 }
.waitlistpage .button-signup {
  margin: 2rem auto 0 auto;
    color: #B88717;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    padding: 17.8438px 53.5312px;
    gap: 11.15px;
    
    width: 100% !important;
    
    
    border-radius: 55.7617px;

    border: 2px solid #B88717;

    background: none;
 }
  /* .waitingListAccess{
    display: flex ;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    background: rgba(51, 52, 86, 0.4);
    backdrop-filter: blur(12px);
    border-radius: 16px;
    padding: 3rem 0;
} */

/* .waitlisttitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 35.6875px;    
  color: #FFFFFF ;  
   } */
  
 
   /* .waitlistIconsA{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin: 0 5px;
    padding: 0 0 0 50px;
 } */

 /* .waitlistIconsA a {
  background: none !important;
  color: none !important;
 } */
/* .icon--image {
  width: 90%;
  padding:10px;
} */
/* .icon--image1 {
  width: 100%;
  padding:12px;
} */

 .fa {
    /* padding: 0.1px; */
    border-radius: 5px;
    width: 50%;
    list-style-image: none;
    background-color: none;
 }
 .faT { background-color: #2CA3DB;}
 .faW { background-color: #2CD44A;}
 .faFa { background-color: #0079FF;}
 .faTe { background-color: #644382;}
 .faV { background-color: #2CA3DB;}
 .faD { background-color: #5C73C4;}
 .faQ { background-color: #8BC34A;}
 .faSi { background-color:#FFFA37;}
 .faL { background-color: #2CCF54;}
 .faP { background-color: #00A0E9;}


 .waitlistpage h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 96px;
  background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 10px 40px rgba(143, 67, 242, 0.2);
 }

 .waitlistpage p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px !important;
  color: #FFFFFF;
}

  

  .rb {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    justify-content: center;

  }

  
  /* .waitlistTitle {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  
    text-align: center;
  
    color: #ffffff;
  } */
   
  .waitlistTitle1{
    margin-top: 4rem;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
    /* identical to box height, or 156% */
  
    text-align: center;
    margin-bottom: 2rem;
  
    color: #ffffff;
  }
  
  /* .waitingListAccess{
  width: 90%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
  margin: auto;
  padding: auto;
  } */
  
 
  .waitlistbutton {    
    margin-top: 2rem;
    border-radius: 25px;
    border: 1px solid #F1C767;
    outline: none;
    color: #fff;
    padding: 10px;
    width: 190px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 10px 22px -6px rgba(102, 109, 255, 0.26);
    transition: all 0.2s ease-in-out;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .waitlistbutton1 {    
    border-radius: 25px;
    border: 1px solid #F1C767;
    outline: none;
    color: #fff;
    padding: 10px;
    width: 190px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 10px 22px -6px rgba(102, 109, 255, 0.26);
    transition: all 0.2s ease-in-out;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
 
  
  .waitPhone::placeholder {
    background: #333456 !important;
      opacity: 1 !important;

  }
  
  .waitlistSubscribe {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 72px;
    text-align: center;
    margin-top: -2rem;
    background: linear-gradient(
      90deg,
      #b88717 -5.35%,
      #ffd166 21.73%,
      #f5d281 42.92%,
      #c48e0e 107.68%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 10px 40px rgba(143, 67, 242, 0.2);
  }

  .mg1 {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  
  .waitPhone::placeholder {
    background: #333456 !important;
      opacity: 1 !important;

  }
  .waitPhone{
    background: #333456;
    border-radius: 8px;
    width: 80%;
    
  }
  
  /* .--PhoneInputCountryFlag-height{
     flagiconheight: 3rem;
  } */
  
  
  .waitlistinfo {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    /* margin-bottom: -2rem; */
    /* or 200% */
  
    text-align: center;
  
    color: #f0f0f0;
  }

 
  
  @media (max-width: 900px) {
    .waitlistpage h4 {
      margin-top: 5rem;
      text-align: center;
    }
    .waitlistpage p {
      text-align: center;
    }
    .icon--image {
      padding: 2px;
      width: 70%;
      
    }
    
}