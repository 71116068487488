.carouselpage {
  padding: 100px 0 180px 0;
  background: #13142c;
}

.carouselpage .row1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.carouselpage .row1 h1{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 62px;
  text-align: center;
  margin-bottom: 1.75rem;
  color: #f1c767;
}


.carouselpage .row1 p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(
    90.76deg,
    #b671c9 -11.96%,
    #5960f5 22.52%,
    #41affa 68.36%,
    #585ff4 93.52%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.carouselpage .row2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  margin: auto;
  padding: 2rem auto;
  background: #191a38;
  box-shadow: 0px 24px 68px #13142c;
  border-radius: 20px;
  margin-bottom: 5rem;
}

.carouselpage .row2 p {  
  padding: 2rem 1.5rem;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #FFFFFF;
}

.carouselpage .row3 .col3 .card1 {
  position: relative;
  background: #191A38;
  box-shadow: 5px 24px 48px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  color: #fff;
}
.carouselpage .row3 .col3 .card1 .body { 
   width: 70%;
}

.carouselpage .row3 .col3 .card1 .title {
  background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.carouselpage .row3 .col3 .card1 .text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 1.5rem;
  /* or 150% */


  color: #FFFFFF;
}

.carouselpage .row3 .col3 .card1 button {
  margin-top: 2rem;
    border-radius: 25px;
    border: 1px solid #F1C767;
    outline: none;
    color: #fff;
    padding: 0.7rem;
    width: 12rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 10px 22px -6px rgba(102, 109, 255, 0.26);
    transition: all 0.2s ease-in-out;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-smallest);
    line-height: 1.5rem;
    background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    
    padding: 10px 20px;
}
.carouselpage .row3 .col3 .card1 .img {
  position: absolute; 
  top: 1rem;
  right:1rem;
  width: 30%;
}

.carouselpage .row3 .col3 .card1 .i1 {  
  top: 3rem !important;
}

.carouselpage .colspan {
  background: yellow;
  margin: auto;
  padding: 2rem;
  background: #333456;
  border-radius: 5px;
  color: #fff;
  width: 80%;
}

.carouselpage .colspan h3 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  color: #FFFFFF;
}

.carouselpage .colspan h2 {
  font-family:  'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.carouselpage .colspan span {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  color: #FFFFFF;
}

.carouselpage .colspan p {
  font-family: 'DM Sans';
  font-style: normal;
  font-size: 20px;
  line-height: 40px;
  color: #FFFFFF;
}



@media (max-width: 500px) {


  .carouselpage .sliderContainer .imagesection {
    text-align: center;
  }

  .carouselpage .sliderContainer .left-arrow{
    top: 105%
  }
  .carouselpage .sliderContainer .right-arrow{
    top: 105%
  }

  .carouselpage {
    padding-bottom: 200px;
  }
  .carouselContainerTitle {
    font-size: 49px;
  }
  .carouselContainersubTitle {
    font-size: 32px;
  }
  .carouselstatements {
    padding: 1rem;
  }
  .carouselParagraph {
    font-size: 22px;
    line-height: 40px;
  }
  .rn {
    margin-top: 10rem;
  }

  .carouselpage .row2 {
    width: 95%;
  }

  .carouselpage .row2 p {  
    padding: 1.5rem 1rem;
  }
}