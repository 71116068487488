.experience {
    padding: 70px 0;
}

.experience h2 {
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 96px;
    background: linear-gradient(180deg, #666DFF 0%, #5057E4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.experience p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
    color: #212121;  
}

.rows {
    display: flex;
    align-items: center;
    justify-content: center;
    

}

.imgawesome {
    width: 100%;
    animation: rotation 1s infinite linear;
    animation: App-logo-spin infinite 10s linear;
}
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(0eg);
    }
  }


@media (max-width: 1200px) {
    
    .experience h2 {
        padding: .01rem;
        width: 100%;
        font-size: 60px;
        line-height: 80px;
    }
}

@media (max-width: 900px) {
    .rows {
        text-align: center;
    }
}
