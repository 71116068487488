.overview {
    
    
    background: #13142c;
    background-image: url("../images/another\ background.png");
    background-position: cover;
    background-repeat: no-repeat;
    font-family: "DM Sans";
    font-style: normal;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    padding-top: 5rem;
    margin: auto;
    

    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.overview .p1 {
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 100px;

    text-align: center !important;

    /* gOLD */

    background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    text-align: center !important;

}

.overview h1 p{
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    font-family: DM Sans;
    width: 65%;
    margin: auto;
    line-height: 45px;
}

.overview-center {
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
    padding: 2rem 0 2rem 0;
    line-height: 72px;
}

.overview-card {
    /* background: var(--overview-card-background); */
    border-radius: 15px;
    padding: 2rem 4rem;
    margin-bottom: 3rem;
    border: 2px solid transparent;
    transition: var(--transition);
    cursor: pointer;
    /* box-shadow: var(--overview-card-boxshadow); */
    background: #191A38;
    box-shadow: 13px 10px 164px -20px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 13px 10px 164px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 13px 10px 164px -20px rgba(0, 0, 0, 0.75);
}

.overview-card:hover {
    border: 2px solid var(--primary-color);
    transition: var(--transition);
}

.overview-card-top {
    align-items: center;
}


.overview-icons {
    transition: transform 0.6s ease;
}

.overview-card:hover .overview-icons {
    transition: transform 0.6s ease;
    transform: rotate(90deg);
}

.overview-card .overview-title {
    color: var(--white-color);
    font-size: var(--font-big);
    font-weight: 400;
    width: 100%;

    /* padding: 20px; */
}
.overview-title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 3rem;
    /* or 200% */

    text-align: center;

    color: #FFFFFF;
}

.overview-card p {
    color: var(--overview-card-text-color);
    width: 100%;
    line-height: 30px;
}

/* ========================================== */
/* THE RESPONSIVENESS */
/* ========================================== */
@media (min-width: 1240px) {
    .overview {
        /* padding: 100px 0 370px 0;
        padding-top: 2rem; */
    }

    .overview small {
        font-size: var(--font-med);
    }

    .overview h1 {
        font-size: var(--font-large);
    }

    .overview-center {
        width: 60%;
    }
}

@media (max-width: 768px) {
    .overview {
        padding: 100px 10px 370px 10px;
        padding-left: 2rem;
    }

    .overview h1 {
        font-size: var(--font-bigger);
    }

    .overview-center {
        width: 100%;
    }
    .overview-card p {
        width: 90%;
    }
}

@media (max-width: 800px) {
    .overview {
        padding: 100px 10px 30px 10px;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
    }
    .overview p {
        font-size: 30px;
    }
    .overview-card {
        padding: 30px 15px;
    }

    .overview-card p {
        width: 95%;
    }
  
}

@media (max-width: 500px) {
    .overview {
        padding: 100px 10px 300px 10px;
    }
    .overview h1 p {
        font-size: 25px !important;
    }

    .overview-card {
        margin: 1rem;
        
    }
    .overview-title {
        font-size: 15px;
    }
    .overview-center {
        /* padding-bottom: -4rem; */
        margin-bottom: -15rem;
    }
}
