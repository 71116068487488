.process {
  padding: 100px 0;

}

.process h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 64px;
}

.process p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.process button {
  margin-top: 2rem;
    border-radius: 25px;
    border: 1px solid #F1C767;
    outline: none;
    color: #fff;
    padding: 0.7rem;
    width: 12rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 10px 22px -6px rgba(102, 109, 255, 0.26);
    transition: all 0.2s ease-in-out;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-smallest);
    line-height: 1.5rem;
    background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 21.73%, #F5D281 42.92%, #C48E0E 107.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    padding: 10px 20px;
}

.process button:hover {
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 500px) {
  .process .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}