.fourthContentContainer {
  background-color: #0e0f1f;
  padding-top: 6rem;
  padding-bottom: 6rem;
 
  
}

.fourth-box {
  width: 80%;
  margin: auto;
  padding: auto;
}
.fourthConatiner {
    text-align: left;
  }

  .fourthcontainerTitle1 {
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    color: #f1c767;
    /* margin-top: 8.5rem; */

    background: linear-gradient(
      90deg, 
      #B88717 -5.35%, 
      #FFD166 21.73%, 
      #F5D281 42.92%, 
      #C48E0E 107.68%
      );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    flex: none;
    order: 0;
    flex-grow: 0;
    /* margin-bottom: 2rem; */
  }

  .fourthcontainerparagraph {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    
  
    /* UAB 3 */
  
    background: linear-gradient(
      90.76deg,
      #b671c9 -11.96%,
      #5960f5 22.52%,
      #41affa 68.36%,
      #585ff4 93.52%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .fourthcontainerparagrap2 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
    /* identical to box height, or 156% */
    color: #FFFFFF;
}
  

.fourthcontainerwidget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: auto;
  padding-bottom: 2rem;
  /* width: 100%; */
  /* height: 100%; */
  /* text-align: left; */
 
}
  
  .fourthcontainerwidgetContainer {
    padding: 2rem 3rem;
    width: 100%;
    background: linear-gradient(134.72deg, #4143b0 25.12%, #7d44a5 74.52%);
    
    border-radius: 1rem;
  }

  .fourthcontainerwidgetContainer:hover {
    border: 2px solid var(--primary-color);
    transition: transform 0.6s ease;
    /* transform: rotate(90deg); */
    transition: var(--transition);
  }

   
  .fouthContentParagraph {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 3rem;
    /* or 200% */
    color: #ffffff;
    text-align: left;
  } 

  .sect1 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  
  .image12 {
   width: 30%;
    /* margin-bottom: 7rem; */
  }

  @media (max-width: 1200px) {

    .fourthcontainerwidget {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
      padding: auto;
      padding-bottom: 2rem;
      /* width: 100%; */
      /* height: 100%; */
      /* text-align: left; */
     
    }
   .fouthContentParagraph {
    text-align: center;
   }
    .fourthcontainerTitle1 {
    }
    .fouthContentParagraph {
      font-size: 20px;
      line-height: 40px;
    }
    .image12 {
      display: none;
    }
    .fourthConatiner {
      text-align: center;
    }
    .fourthcontainerTitle1 {
      font-size: 48px;
      text-align: center;
    }
    .fourthcontainerparagraph {
      font-size: 38px;
    }
    .sect1 {
      margin-bottom: 1rem;
    }
    .fourthcontainerparagrap2 {
      font-size: 25px;
    }
    .fourthcontainerparagraph {
      font-size: 30px;
    }
}

  @media (max-width: 1000px) {
    .fourthContentContainer {
      

    }
    .fourthcontainerTitle1 {
    }
    .fouthContentParagraph {
      font-size: 20px;
      line-height: 40px;
    }
    .image12 {
      display: none;
    }
    .fourthConatiner {
      text-align: center;
    }
    .fourthcontainerTitle1 {
      font-size: 48px;
      text-align: center;
    }
    .fourthcontainerparagraph {
      font-size: 38px;
    }
    .sect1 {
      margin-bottom: 1rem;
    }
    .fourthcontainerparagrap2 {
      font-size: 25px;
    }
    .fourthcontainerparagraph {
      font-size: 30px;
    }
}

  @media (max-width: 800px) {
    .fourthContentContainer {

    }
    .fourthcontainerTitle1 {
    }
    .fouthContentParagraph {
      font-size: 20px;
      line-height: 40px;
    }
    .image12 {
      display: none;
    }
    .fourthConatiner {
      text-align: center;
    }
    .fourthcontainerTitle1 {
      font-size: 48px;
      text-align: center;
    }
    .fourthcontainerparagraph {
      font-size: 38px;
    }
    .sect1 {
      margin-bottom: 1rem;
    }
    .fourthcontainerparagrap2 {
      font-size: 25px;
    }
    .fourthcontainerparagraph {
      font-size: 30px;
    }
} 
  
  @media (max-width: 500px) {
    .fourthContentContainer {

    }
    .fourthcontainerTitle1 {
    }
    .fouthContentParagraph {
      font-size: 20px;
      line-height: 40px;
    }
    .image12 {
      display: none;
    }
    .fourthConatiner {
      text-align: center;
    }
    .fourthcontainerTitle1 {
      font-size: 48px;
      text-align: center;
    }
    .fourthcontainerparagraph {
      font-size: 38px;
    }
    .sect1 {
      margin-bottom: 1rem;
    }
    .fourthcontainerparagrap2 {
      font-size: 28px;
    }
    .fourthcontainerparagraph {
      font-size: 30px;
    }
    .fourth-box {
      width: 95%;
    }
} 
    